const STATIC_STYLES = {
  NAVBAR_HEIGHT: 75,
  FOOTER_HEIGHT: 70,
  SIDEBAR_CLOSED_WIDTH: 75,
  SIDEBAR_OPEN_WIDTH: 240,
  SIDEBAR_TRANSITION_DURATION: "0.4s",
  SEARCH_BAR_HEIGHT: 40,
  PAGE_CONTAINER_HEIGHT_DESKTOP_PADDING: 185,
  PAGE_CONTAINER_HEIGHT_TABLET_MOBILE_PADDING: 225,

  TABLET_MAX_WIDTH: 1025,
  MOBILE_MAX_WIDTH: 580,
};

export { STATIC_STYLES };
